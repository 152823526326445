<template>
  <Transition name="modal" :duration="600">
    <div
      class="fixed z-50"
      aria-labelledby="modal-title"
      role="dialog"
      v-show="state"
      aria-modal="true"
    >
      <div
        class="fixed top-0 right-0 left-0 bottom-0 bg-gray-400 bg-opacity-75 transition-opacity"
      />

      <div
        @click="onClickOutside"
        class="fixed top-0 right-0 left-0 bottom-0 z-10 overflow-y-hidden"
      >
        <div
          id="dialog"
          class="bounce-modal-animation flex min-h-full items-center justify-center p-0 px-4 text-center"
        >
          <div
            @click.stop
            class="relative h-min w-full rounded-lg bg-background p-8 shadow-xl"
          >
            <!--              Icons-->
            <div
              class="absolute top-[-80px] right-0 left-0 z-20 flex flex-col items-center justify-center rounded-full"
            >
              <svg
                fill="white"
                class="h-[150px] w-[150px]"
                stroke="currentColor"
                viewBox="0 0 24 24"
                :color="validateColor('icon')"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  v-if="type === 'error'"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                  color="#FF5252"
                  fill-rule="nonzero"
                  d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
                <path
                  v-if="type === 'success' || type === 'warning'"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                  fill-rule="nonzero"
                  :color="validateColor('icon')"
                  d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </div>
            <!--              Content-->
            <div class="mt-10 flex flex-col items-center text-grey">
              <div class="mb-3 flex justify-center space-x-2">
                <div
                  class="flex items-center justify-center text-xs"
                  v-if="appVersion"
                >
                  {{ appVersion }}
                </div>
                <div
                  v-if="platformApp"
                  class="flex items-center justify-center text-xs"
                >
                  {{ platformApp }}
                </div>
              </div>
              <div class="font-semibold" v-if="description">
                {{ description }}
              </div>

              <div class="font-semibold" v-if="htmlDescription">
                <p v-html="htmlDescription"></p>
              </div>
              <slot name="description" />
              <div
                v-if="
                  (isPaymentsOff === false &&
                    cards?.length &&
                    cardIsActivate) ||
                  isActiveCard === 'Подтвердить карту'
                "
                class="m-auto"
              >
                <button-component
                  @clickAction="
                    $emit(
                      isActiveCard === 'Подтвердить карту'
                        ? 'confirmCard'
                        : 'decline',
                    )
                  "
                  :full-width="false"
                  height="10"
                  class="mt-5"
                  input-class="px-12"
                  rounded="lg"
                  title="Написать в парк"
                  text-color="white"
                  :whatsappIcon="true"
                  :color="validateColor('button')"
                />
                <button
                  @click="$emit('confirm')"
                  height="10"
                  class="mt-5 font-semibold"
                  :color="validateColor('button')"
                >
                  Отмена
                </button>
              </div>
              <button-component
                v-else
                @clickAction="$emit('confirm')"
                :full-width="false"
                height="10"
                class="mt-5"
                input-class="px-12"
                rounded="lg"
                :title="buttonText"
                text-color="white"
                :color="validateColor('button')"
              />
            </div>
            <!--            Footer-->
            <div
              class="absolute bottom-[-10px] left-0 right-0 z-[-1] ml-auto mr-auto h-12 w-11/12 rounded-lg"
              :class="{
                'bg-red': type === 'error',
                'bg-green': type === 'success',
                'bg-primary': type === 'warning',
              }"
            />
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script lang="ts">
import ButtonComponent from "@/components/Common/ButtonComponent.vue";
import { ICard } from "@/struct/IHomePage/ICabinetBalance";
import { App } from "@capacitor/app";
import { Getter } from "s-vuex-class";
import { Options, Vue } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

@Options({
  components: { ButtonComponent },
})
export default class StatusModal extends Vue {
  @Prop() public state!: boolean;
  @Prop() public description!: string;
  @Prop() public htmlDescription!: string;
  @Prop() public type!: "error" | "success" | "warning";
  @Prop() public buttonText!: string;
  @Prop() public isPaymentsOff!: boolean;
  @Prop() public cards!: ICard[] | undefined;
  @Prop() public cardIsActivate!: boolean | null;
  @Prop() public isActiveCard!: string;

  @Getter("validateUserPlatform") private platformApp: string | undefined;

  private appVersion: string | null = null;

  @Watch("state")
  watchModalState(new_value: boolean) {
    if (new_value) {
      return document.body.classList.add("overflow-hidden");
    }
    if (!new_value) {
      return document.body.classList.remove("overflow-hidden");
    }
  }

  private validateColor(type: "icon" | "button") {
    if (type == "button") {
      if (this.type === "error") return "red";
      if (this.type === "warning") return "primary";
      if (this.type === "success") return "green";
    }

    if (type === "icon") {
      if (this.type === "error") return "#FF5252";
      if (this.type === "warning") return "#FFD92F";
      if (this.type === "success") return "#43A047";
    }
  }

  private onClickOutside() {
    const dialog = document.getElementById("dialog") as HTMLDivElement;
    dialog.classList.add("bounce-modal-animation");
    setTimeout(() => {
      dialog.classList.remove("bounce-modal-animation");
    }, 100);
  }

  unmounted() {
    document.body.classList.remove("overflow-hidden");
  }

  created() {
    if (this.$PLATFORM != "web") {
      App.getInfo().then((info) => {
        this.appVersion = `v${info.version} ${info.build}`;
      });
    }
  }
}
</script>
