import ApiCard from "@/api/ApiCard";
import ApiEnter from "@/api/ApiEnter";
import ApiLinkSupport from "@/api/ApiLinkSupport";
import { ELoginStep } from "@/struct/ELogin/ELoginStep";
import { IAppVersion } from "@/struct/IAppVersion/IAppVersion";
import { ICard } from "@/struct/IHomePage/ICabinetBalance";
import { ILinkSupport } from "@/struct/ILinkSupport/ILinkSupport";
import { IStoreState, IUserData } from "@/struct/IStore/IStore";
import { Capacitor } from "@capacitor/core";
import { createStore } from "vuex";

export default createStore({
  state: {
    platform: Capacitor.getPlatform(),
    isOpenNewVersionModal: true,
    currentAppVersion: "1.8.2",
    appVersion: null,
    connectionState: true,
    userData: null,
    linkSupport: null,
    userCards: undefined,
    deferredPrompt: null,
    currentLoginStep: ELoginStep.EnterPhone,
    isNeedRegistration: false,
    loginPhone: "",
    loginPassword: "",
  },
  getters: {
    validateUserPlatform(state: IStoreState) {
      if (state.platform === "web") {
        if (window.matchMedia("(display-mode: standalone)").matches) {
          return "Fstore";
        } else {
          return "Web";
        }
      } else if (state.platform === "android") {
        return "Android";
      } else if (state.platform === "ios") {
        return "IOS";
      } else {
        return false;
      }
    },
    getConnectionState(state: IStoreState): boolean {
      return state.connectionState;
    },
    getUserData(state: IStoreState): IUserData | null {
      return state.userData;
    },
    getLinkSupport(state: IStoreState): ILinkSupport | null {
      return state.linkSupport;
    },
    getCurrentAppVersion(state: IStoreState): string {
      return state.currentAppVersion;
    },
    getAppVersion(state: IStoreState): IAppVersion | null {
      return state.appVersion;
    },
    getUserCards(state: IStoreState): ICard[] | undefined {
      return state.userCards;
    },
    getDeferredPromo(state: IStoreState): unknown {
      return state.deferredPrompt;
    },
    getCurrentLoginStep(state: IStoreState): ELoginStep {
      return state.currentLoginStep;
    },
    getLoginPhone(state: IStoreState): string {
      return state.loginPhone;
    },
    getLoginPassword(state: IStoreState): string {
      return state.loginPassword;
    },
    getIsNeedRegistration(state: IStoreState): boolean {
      return state.isNeedRegistration;
    },
  },
  mutations: {
    changeConnectionState(state: IStoreState, value: boolean): void {
      state.connectionState = value;
    },
    setUserData(state: IStoreState, data: IUserData): void {
      state.userData = data;
    },
    setAppVersion(state: IStoreState, data: IAppVersion): void {
      state.appVersion = data;
    },
    setLinkSupport(state: IStoreState, data: ILinkSupport): void {
      state.linkSupport = data;
    },
    setUserCards(state: IStoreState, data: ICard[]): void {
      state.userCards = data;
    },
    setDeferredPrompt(state: IStoreState, data: unknown) {
      state.deferredPrompt = data;
    },
    setCurrentStep(state: IStoreState, data: ELoginStep) {
      state.currentLoginStep = data;
    },
    setLoginPhone(state: IStoreState, value: string) {
      state.loginPhone = value;
    },
    setLoginPassword(state: IStoreState, value: string) {
      state.loginPassword = value;
    },
    setIsNeedRegistration(state: IStoreState, value: boolean) {
      state.isNeedRegistration = value;
    },
  },
  actions: {
    async getUserData(context) {
      const response = await ApiEnter.getAccountUser();
      if (typeof response != "boolean") context.commit("setUserData", response);
    },

    async getAppVersion(context) {
      const response = await ApiEnter.getAppVersion();
      context.commit("setAppVersion", response);
    },

    async getLinkSupport(context) {
      const response = await ApiLinkSupport.getLinkSupport();
      if (typeof response != "boolean")
        context.commit("setLinkSupport", response);
    },

    async fetchUserCards(context) {
      const response = await ApiCard.getCards();
      context.commit("setUserCards", response);
    },
    async logout(context) {
      context.commit("setUserData", null);
      context.commit("setUserCards", undefined);
    },
  },
  modules: {},
});
