<template>
  <div class="login-container bg-background" id="login">
    <div class="login-form">
      <div class="flex justify-center">
        <div class="absolute top-1 flex justify-center space-x-2">
          <div
            v-if="appVersion && appBuild"
            class="text-sm text-grey text-opacity-30"
          >
            v{{ appVersion }} {{ appBuild }}
          </div>
          <div
            v-if="platformApp || !appVersion"
            class="flex justify-center text-sm text-grey text-opacity-30"
          >
            {{ platformApp }}
          </div>
        </div>
      </div>
      <div
        class="absolute top-6 left-0 flex w-full select-none items-center justify-between px-[24px]"
      >
        <!--        <div class="flex space-x-2 h-9 bg-black rounded-full justify-center items-center px-3"-->
        <!--             @click="openYandexAuth"-->
        <!--             v-if="currentStep != enumLoginSteps.EnterPhone">-->
        <!--          <img src="@/assets/img/yandex-short.png" alt="" class="w-5">-->
        <!--          <p class="text-white text-sm">Войти с Яндекс ID</p>-->
        <!--        </div>-->
        <div
          v-if="currentStep === enumLoginSteps.CreatePass"
          class="flex h-9 items-center justify-center rounded-lg bg-greyLight px-4 text-grey"
          @click="changePhone"
        >
          <i class="fas fa-chevron-left" />
          <div class="ml-3 text-sm">Назад</div>
        </div>
        <div
          v-if="currentStep === enumLoginSteps.RepeatPass"
          class="flex h-9 items-center justify-center rounded-lg bg-greyLight px-4 text-grey"
          @click="currentStep = enumLoginSteps.CreatePass"
        >
          <i class="fas fa-chevron-left" />
          <div class="ml-3 text-sm">Назад</div>
        </div>
        <div
          v-if="currentStep === enumLoginSteps.TelegramRedirect"
          class="flex h-9 items-center justify-center rounded-lg bg-greyLight px-4 text-grey"
          @click="currentStep = enumLoginSteps.EnterPhone"
        >
          <i class="fas fa-chevron-left" />
          <div class="ml-3 text-sm">Назад</div>
        </div>

        <div
          v-if="currentStep === enumLoginSteps.CheckOTP"
          class="flex h-9 items-center justify-center rounded-lg bg-greyLight px-4 text-grey"
          @click="resetData"
        >
          <i class="fas fa-chevron-left" />
          <div class="ml-3 text-sm">В начало</div>
        </div>
      </div>
      <div class="flex justify-center" @click="helpLoginDialog = true">
        <div
          class="absolute bottom-1 flex flex-col items-center rounded-full bg-primary px-4 py-1 text-sm font-medium text-grey"
        >
          <div class="flex items-center">
            <div class="text-2xl">
              <i class="fab fa-whatsapp" />
            </div>
            <div class="ml-2 text-base">Не могу войти</div>
          </div>
        </div>
      </div>
      <div v-auto-animate>
        <template v-if="currentStep === enumLoginSteps.EnterPhone">
          <LoginStep
            :title="['Вход в кабинет выплат для', 'водителей и курьеров']"
            subtitle="Введите ваш номер телефона в поле ниже"
          >
            <template v-slot:action>
              <div class="form-input">
                <InputComponent
                  v-model="phone"
                  :model="phone"
                  @keyup="validateFieldInput('phone')"
                  maxlength="15"
                  rounded="md"
                  id="loginPhone"
                  prefix="+7"
                  height="h-14"
                  :border="false"
                  @paste="onPaste"
                  :disabled="hasRequest"
                  background="bg-greyLight"
                  :mask="'(###) ### ## ##'"
                  placeholder="(***) *** ** **"
                  type="tel"
                />
                <div class="mt-5">
                  <CheckboxComponent
                    :disabled="hasRequest"
                    :model="termsConditionFirst"
                    @update="(value) => (termsConditionFirst = value)"
                  >
                    <template v-slot:label>
                      Я ознакомился и согласен с
                      <a
                        @click.stop="
                          openLink('https://legal.force2.ru/privacy/forcepro')
                        "
                        href="#"
                        class="text-blue underline"
                      >
                        политикой конфиденциальности
                      </a>
                    </template>
                  </CheckboxComponent>
                  <CheckboxComponent
                    :disabled="hasRequest"
                    :model="termsConditionSecond"
                    @update="(value) => (termsConditionSecond = value)"
                    class="mt-4"
                  >
                    <template v-slot:label>
                      Я ознакомился и согласен с
                      <a
                        @click.stop="
                          openLink('https://legal.force2.ru/forcepro/agreement')
                        "
                        href="#"
                        class="text-blue underline"
                      >
                        пользовательским соглашением
                      </a>
                    </template>
                  </CheckboxComponent>
                </div>
                <ButtonComponent
                  type="submit"
                  title="Продолжить"
                  font="normal"
                  rounded="md"
                  :disabled="
                    phone.length !== 15 ||
                    !termsConditionSecond ||
                    !termsConditionFirst
                  "
                  class="mt-5 h-14 font-normal"
                  :loading="hasRequest"
                  :color="
                    phone.length === 15 &&
                    termsConditionSecond &&
                    termsConditionFirst
                      ? 'primary'
                      : 'greyLight'
                  "
                  @clickAction="sendPhoneToValidate()"
                />
                <!--                <button-->
                <!--                  @click="openYandexAuth"-->
                <!--                  class="w-full flex justify-center space-x-3 items-center p-4 h-[56px] mt-3 text-white text-base rounded-md font-medium bg-black">-->
                <!--                  <img src="@/assets/img/yandex-short.png" alt="" class="w-8 h-8">-->
                <!--                  <p>Войти с Яндекс ID</p>-->
                <!--                </button>-->
              </div>
            </template>
          </LoginStep>
        </template>
        <template v-if="currentStep === enumLoginSteps.CreatePass">
          <login-step
            :subtitle="
              isNeedRegistration
                ? 'Это НЕ СМС и НЕ ЗВОНОК'
                : 'Который вы установили при первом входе'
            "
            :title="
              isNeedRegistration
                ? ['Придумайте свой пароль']
                : ['Введите пароль']
            "
            :attention="
              passwordError && passwordError.length ? passwordError : null
            "
          >
            <template v-slot:action>
              <div class="form-password">
                <div class="flex">
                  <template v-for="i in 5" :key="i">
                    <input
                      :class="{ active: password[i - 1] }"
                      @keyup.delete="keyDownEvent('password')"
                      @input="
                        inputPasswordEvent('password', $event.target.value)
                      "
                      ref="input"
                      type="password"
                      :value="password[i - 1]"
                      @focus="onFocusPassword('password')"
                      inputmode="numeric"
                      pattern="[0-9]*"
                      autocomplete="off"
                      maxlength="1"
                      class="input-box focus:border focus:border-yellow-200"
                    />
                  </template>
                </div>
                <button-component
                  :disabled="password.length < 5"
                  class="form-button mb-6"
                  :loading="hasRequest"
                  id="continueButton"
                  font="normal"
                  type="submit"
                  rounded="md"
                  :color="password.length === 5 ? 'primary' : 'greyLight'"
                  @clickAction="validateIsNeedRegistration"
                  title="Продолжить"
                />
              </div>
            </template>
          </login-step>
        </template>
        <template v-if="currentStep === enumLoginSteps.TelegramRedirect">
          <login-step
            :phone="phone"
            title="
                            Вы можете установить пароль к приложению через Telegram или получить звонок.

            "
          >
            <template v-slot:action>
              <div class="flex justify-center text-center">
                Это обезопасит вас от мошенников и вы сможете получать коды
                доступа для входа
              </div>
              <div>
                <button-component
                  class="form-button mb-3"
                  font="normal"
                  rounded="md"
                  color="primary"
                  @clickAction="openTelegram"
                  title="Перейти в Telegram"
                />
                <button-component
                  class="form-button mb-6"
                  font="normal"
                  rounded="md"
                  color="primary"
                  @clickAction="currentStep = enumLoginSteps.CreatePass"
                  title="Установить пароль и получить звонок"
                />
              </div>
            </template>
          </login-step>
        </template>
        <template v-if="currentStep === enumLoginSteps.RepeatPass">
          <login-step
            :title="['Повторите пароль']"
            :attention="
              !passwordRepeat.length && passwordRepeatError
                ? passwordRepeatError
                : null
            "
          >
            <template v-slot:action>
              <div class="form-password">
                <div class="flex">
                  <template v-for="i in 5" :key="i">
                    <input
                      :class="{ active: passwordRepeat[i - 1] }"
                      ref="input"
                      @keyup.delete="keyDownEvent('passwordRepeat')"
                      @focus="onFocusPassword('passwordRepeat')"
                      :disabled="hasRequest"
                      @input="
                        inputPasswordEvent(
                          'passwordRepeat',
                          $event.target.value,
                        )
                      "
                      :value="passwordRepeat[i - 1]"
                      inputmode="numeric"
                      pattern="[0-9]*"
                      type="password"
                      autocomplete="off"
                      maxlength="1"
                      class="input-box focus:border focus:border-yellow-200"
                    />
                  </template>
                </div>

                <button-component
                  :disabled="
                    passwordRepeat.length < 5 || passwordRepeat !== password
                  "
                  class="form-button"
                  font="normal"
                  type="submit"
                  :loading="hasRequest"
                  rounded="md"
                  :color="passwordRepeat.length === 5 ? 'primary' : 'greyLight'"
                  title="Продолжить"
                  @clickAction="activateUser"
                />
              </div>
            </template>
          </login-step>
        </template>
        <template v-if="currentStep === enumLoginSteps.CheckOTP">
          <login-step
            :attention="otpError && otpError.length ? otpError : null"
            :title="
              loginType === 'phone'
                ? ['На ваш телефон поступит звонок', 'Возьмите трубку']
                : ['Вам в Telegram отправлен код для входа', 'Впишите его ниже']
            "
          >
            <template v-slot:action>
              <div class="form-input">
                <input-component
                  v-model="otp"
                  :model="otp"
                  height="h-14"
                  rounded="md"
                  :border="false"
                  background="bg-greyLight"
                  :loading="hasRequest"
                  @input="validateOTP"
                  @keyup="validateFieldInput('otp')"
                  maxlength="5"
                  :placeholder="
                    loginType == 'phone' ? 'Код из звонка' : 'Код из Telegram'
                  "
                  outlined
                  type="tel"
                />
              </div>
              <template v-if="callsTries < 2 && loginType == 'phone'">
                <div
                  class="mt-2 flex justify-center text-grey"
                  v-if="nextCallTime > 0"
                >
                  Позвонить еще раз ({{ nextCallTime }}c)
                </div>
                <div
                  v-else
                  class="mt-2 flex cursor-pointer justify-center text-blue"
                  @click="sendNewCall"
                >
                  Позвонить еще раз
                </div>
                <!-- <div class="flex justify-center text-center mt-5">
                  Если звонок не приходит, вернитесь в начало и установите
                  пароль через телеграм
                </div> -->
                <TelegramBanner class="mt-5" />
              </template>
            </template>
          </login-step>
        </template>
      </div>

      <confirm-modal
        :description="'У Вас трудность? Решим! Подготовьте скриншот экрана!'"
        :state="helpLoginDialog"
        decline-button-text="Не сейчас"
        @confirm="openWhatsApp"
        @decline="helpLoginDialog = false"
      >
        <template v-slot:confirm-title>
          <div class="flex items-center font-medium">
            В WhatsApp
            <div class="ml-2 text-2xl">
              <i class="fab fa-whatsapp" />
            </div>
          </div>
        </template>
      </confirm-modal>
    </div>
  </div>
</template>

<script lang="ts">
import ApiEnter from "@/api/ApiEnter";
import ButtonComponent from "@/components/Common/ButtonComponent.vue";
import CheckboxComponent from "@/components/Common/CheckboxComponent.vue";
import InputComponent from "@/components/Common/InputComponent.vue";
import LoginStep from "@/components/Enter/LoginStep.vue";
import TelegramBanner from "@/components/MainPage/TelegramBanner.vue";
import ConfirmModal from "@/components/Modals/ConfirmModal.vue";
import StatusModal from "@/components/Modals/StatusModal.vue";
import { ELoginStep } from "@/struct/ELogin/ELoginStep";
import UtilStorage from "@/util/Common/UtilStorage";
import UtilError from "@/util/ValidateError/UtilError";
import { App } from "@capacitor/app";
import { Browser } from "@capacitor/browser";
import * as Sentry from "@sentry/vue";
import { Action, Getter, Mutation } from "s-vuex-class";
import { Options, Vue } from "vue-class-component";
import { Watch } from "vue-property-decorator";

@Options({
  components: {
    TelegramBanner,
    ConfirmModal,
    StatusModal,
    CheckboxComponent,
    ButtonComponent,
    InputComponent,
    LoginStep,
  },
  name: "LoginPage",
})
export default class LoginPage extends Vue {
  @Action("getUserData") private getUserData: (() => void) | undefined;
  @Action("fetchUserCards") private fetchUserCards: (() => void) | undefined;

  @Getter("validateUserPlatform") private platformApp: string | undefined;

  @Getter("getCurrentLoginStep") private getCurrentStep: ELoginStep | undefined;
  @Mutation("setCurrentStep") private setCurrentStep:
    | ((value: ELoginStep) => void)
    | undefined;

  @Getter("getLoginPhone") private getLoginPhone: string | undefined;
  @Mutation("setLoginPhone") private setLoginPhone:
    | ((value: string) => void)
    | undefined;

  @Getter("getLoginPassword") private getLoginPassword: string | undefined;
  @Mutation("setLoginPassword") private setLoginPassword:
    | ((value: string) => void)
    | undefined;

  @Getter("getIsNeedRegistration") private getIsNeedRegistration:
    | boolean
    | undefined;
  @Mutation("setIsNeedRegistration") private setIsNeedRegistration:
    | ((value: boolean) => void)
    | undefined;

  @Getter("getConnectionState") private statusConnection: boolean | undefined;

  declare $refs: {
    vue: Vue;
    element: HTMLInputElement;
    input: HTMLInputElement;
    elements: HTMLInputElement[];
  };

  @Watch("password")
  watchPasswordInput(value: string) {
    const MAX_PASSWORD_LENGTH = 5;

    if (value.length > MAX_PASSWORD_LENGTH) {
      this.password = value.substring(0, MAX_PASSWORD_LENGTH);
    }

    if (value.length === MAX_PASSWORD_LENGTH && this.isNeedRegistration) {
      const isEasyPassword = value
        .split("")
        .map((char) => (value.match(new RegExp(char, "g")) || []).length > 2)
        .includes(true);

      if (isEasyPassword) {
        this.passwordError = "Пароль слишком легкий, попробуйте другой";
        this.password = "";
      } else {
        this.passwordError = "";
      }
    }
  }

  @Watch("passwordRepeat")
  watchPasswordRepeat(value: string): void {
    const MAX_PASSWORD_LENGTH = 5;

    if (value.length === MAX_PASSWORD_LENGTH && value !== this.password) {
      this.passwordRepeatError = "Пароли не совпадают, попробуйте еще раз";
      this.passwordRepeat = "";
    }
  }

  get currentStep(): ELoginStep {
    return this.getCurrentStep as ELoginStep;
  }

  set currentStep(value: ELoginStep) {
    if (this.setCurrentStep) {
      this.setCurrentStep(value);
    }
  }

  get phone(): string {
    return this.getLoginPhone as string;
  }

  set phone(value: string) {
    if (this.setLoginPhone) {
      this.setLoginPhone(value);
    }
  }

  get password(): string {
    return this.getLoginPassword as string;
  }

  set password(value: string) {
    if (this.setLoginPassword) {
      this.setLoginPassword(value);
    }
  }

  get isNeedRegistration(): boolean {
    return this.getIsNeedRegistration as boolean;
  }

  set isNeedRegistration(value: boolean) {
    if (this.setIsNeedRegistration) {
      this.setIsNeedRegistration(value);
    }
  }

  private passwordRepeat = "";
  private otp = "";
  private enumLoginSteps = ELoginStep;
  private passwordRepeatError = "";
  private passwordError = "";
  private otpError = "";
  private hasRequest = false;
  private termsConditionFirst = false;
  private termsConditionSecond = false;

  private callsTries = 0;
  private nextCallTime = 100;

  private loginType: "phone" | "telegram" = "phone";

  private appVersion: null | string = null;
  private appBuild: null | string = null;

  private helpLoginDialog = false;

  public async sendPhoneToValidate(): Promise<void> {
    // Установка начальных состояний.
    this.isNeedRegistration = false;
    this.hasRequest = true;

    // Централизованная обработка форматирования телефона.
    const phone = "+7" + this.phone.replace(/\D/g, "");

    // Вызов API для валидации номера телефона.
    const response = await ApiEnter.validateEnter(phone);
    const responseDetail = response?.detail.toLowerCase();

    // Обработка различных сценариев ответа от API.
    if (responseDetail === "user_not_activation") {
      this.isNeedRegistration = true;
      this.currentStep = ELoginStep.TelegramRedirect;
    } else if (responseDetail === "user_activate") {
      this.currentStep = ELoginStep.CreatePass;
    } else {
      // Обработка ошибок для неизвестных деталей ответа.
      UtilError.validateErrorMessage(responseDetail, phone);
    }

    // Обновление номера телефона только при изменении этапа.
    if (
      responseDetail === "user_not_activation" ||
      responseDetail === "user_activate"
    ) {
      this.phone = phone;
    }

    // Сброс флага запроса.
    this.hasRequest = false;
  }

  private async validateIsNeedRegistration(): Promise<void> {
    if (this.isNeedRegistration) {
      this.currentStep = ELoginStep.RepeatPass;
      return;
    }

    await this.loginApp();
  }

  private async sendNewCall() {
    if (this.callsTries < 2) {
      this.hasRequest = true;

      if (!this.isNeedRegistration) {
        const response = await ApiEnter.loginUser(this.phone, this.password);

        if (response?.detail === "USER_NOT_VERIFICATION") {
          this.callsTries += 1;
          this.startOTPInterval();
        }
      }

      if (this.isNeedRegistration) {
        const response = await ApiEnter.activateUser(this.phone, this.password);
        if (response?.detail == "CONFIRMATION_REQUIRED") {
          if (response?.otp_status?.toLowerCase() === "tm_send") {
            this.loginType = "telegram";
          }
          this.callsTries += 1;
          this.startOTPInterval();
        } else {
          UtilError.validateErrorMessage(response?.detail, this.phone);
          Sentry.captureMessage("Error with activateUser", {
            extra: {
              responseBack: JSON.stringify(response),
              requestData: `phone: ${this.phone}, password: ${this.password}`,
            },
          });
        }
      }

      this.hasRequest = false;
    }
  }

  public async openYandexAuth() {
    let url;
    if (this.$PLATFORM === "ios") {
      url = "capacitor://localhost";
    }
    if (this.$PLATFORM === "web") {
      url = `http://${document.location.hostname}:8080`;
    }

    if (this.$PLATFORM === "android") {
      url = `http://localhost`;
    }

    await UtilStorage.setItem("phoneRegisterYandex", this.phone);

    document.location.href = `https://oauth.yandex.com/authorize?response_type=code&client_id=ca1d664ca450408da516c63004184100&redirect_uri=${encodeURIComponent(
      `${url}/#/login-yandex`,
    )}`;
  }

  private async loginApp() {
    if (this.hasRequest) {
      return;
    }

    this.hasRequest = true;

    const response = await ApiEnter.loginUser(this.phone, this.password);

    this.hasRequest = false;

    if (typeof response != "boolean" && response?.access_token) {
      await UtilStorage.setItem("token", response.access_token);
      await UtilStorage.setItem("token_type", response.token_type as string);

      const visitorId = await UtilStorage.getItem("visitor_id");

      if (!visitorId) {
        await UtilStorage.setItem(
          "visitor_id",
          "66102dd837dd470f82bf511e2fe097c9",
        );
      }
      Sentry.setUser({ username: this.phone });
      await this.savePhoneToStorage();
      await this.redirectToHome();
      this.getUserData?.();
      this.resetData();
      return;
    }

    if (response?.detail == "USER_NOT_VERIFICATION") {
      if (response?.otp_status?.toLowerCase() === "tm_send") {
        this.loginType = "telegram";
      }
      this.currentStep = ELoginStep.CheckOTP;
      this.startOTPInterval();
      return;
    }

    if (response?.detail) {
      UtilError.validateErrorMessage(response.detail, this.phone);
      await Sentry.setUser({ username: this.phone });
      Sentry.captureMessage("Error with loginUserWithPhone", {
        extra: {
          responseBack: JSON.stringify(response),
          requestData: `phone: ${this.phone}`,
        },
      });
      this.resetData();
    }
  }

  private startOTPInterval(otp_interval?: number) {
    this.nextCallTime = otp_interval || 100;
    const otpInterval = setInterval(() => {
      this.nextCallTime -= 1;

      if (this.nextCallTime <= 0) {
        clearInterval(otpInterval);
      }
    }, 1000);
  }

  private async savePhoneToStorage() {
    await UtilStorage.setItem("phone", this.phone.replace(/\D/g, ""));
  }

  private async redirectToHome() {
    await this.$router.replace("/home");
  }

  private validateFieldInput(field: "otp" | "phone"): void {
    this[field] = this[field].replace(/[^\d, ^s()]/g, "");
  }

  private onFocusPassword(field: "password" | "passwordRepeat") {
    const input = this.$refs.input as unknown as HTMLInputElement[];
    const currentField = input[this[field].length];
    if (currentField) currentField.select();
  }

  private onPaste(evt: ClipboardEvent) {
    if (!evt.clipboardData) {
      return;
    }
    let data = evt.clipboardData.getData("text").replace(/[^\d]/g, "");
    if (data.length === 11 && (data.startsWith("7") || data.startsWith("8"))) {
      this.phone = data.substring(1);
    } else {
      this.phone = data;
    }
  }

  private inputPasswordEvent(
    field: "password" | "passwordRepeat",
    value: string,
  ): void {
    const cleanedValue = value.replace(/\D/g, "");
    this[field] += cleanedValue;
    if (this[field].length < 5) {
      const input = this.$refs.input as unknown as HTMLInputElement[];
      input[this[field].length].select();
    }
  }

  private keyDownEvent(field: "password" | "passwordRepeat") {
    this[field] = this[field].substring(0, this[field].length - 1);
    const input = this.$refs.input as unknown as HTMLInputElement[];
    const currentInput = input[this[field].length];
    if (currentInput) {
      currentInput.select();
    }
  }

  private async validateOTP(): Promise<void> {
    if (this.otp.length !== 5) {
      return;
    }

    if (!this.statusConnection) {
      this.otpError =
        this.loginType == "phone"
          ? "Положите трубку и введите код из звонка!"
          : "Восстановите подключение к интернету и введите код из Telegram!";
      this.otp = "";
      return;
    }

    this.hasRequest = true;
    this.otpError = "";

    let response;

    if (this.isNeedRegistration) {
      response = await ApiEnter.sendCodeActivation(this.otp);
    } else {
      response = await ApiEnter.sendCode(this.otp);
    }

    this.hasRequest = false;

    if (typeof response != "boolean" && response?.access_token) {
      await UtilStorage.setItem("token", response.access_token);
      await UtilStorage.setItem("visitor_id", response.visitor_id);
      await UtilStorage.setItem("token_type", response.token_type as string);
      Sentry.setUser({ username: this.phone });
      await this.savePhoneToStorage();
      await this.redirectToHome();
      this.getUserData?.();
      this.resetData();
      return;
    }

    if (
      typeof response != "boolean" &&
      response.detail === "ACCOUNT_ACTIVATED"
    ) {
      await UtilStorage.setItem("visitor_id", response.visitor_id);
      await this.loginApp();
      return;
    }

    switch (response?.detail) {
      case "INVALID_CONFIRMATION_CODE":
        this.otpError = "Введен неправильный код, попробуйте еще раз";
        this.otp = "";
        break;

      case "INVALID_CODE":
        this.otpError = "Введен неправильный код, попробуйте еще раз";
        this.otp = "";
        break;

      case "LIMITING_INPUT_ATTEMPTS":
        this.resetData();
        UtilError.validateErrorMessage("LIMITING_INPUT_ATTEMPTS", this.phone);
        break;

      default:
        UtilError.validateErrorMessage(response.detail, this.phone);
        Sentry.captureMessage("Error with sendOtp", {
          extra: {
            responseBack: JSON.stringify(response),
            requestData: JSON.stringify(this.otp),
          },
        });
        break;
    }
  }

  private async activateUser(): Promise<void> {
    this.hasRequest = true;
    const response = await ApiEnter.activateUser(this.phone, this.password);
    this.hasRequest = false;

    if (
      typeof response != "boolean" &&
      response?.detail === "CONFIRMATION_REQUIRED"
    ) {
      if (response.otp_status === "tm_send") this.loginType = "telegram";
      this.currentStep = this.enumLoginSteps.CheckOTP;
      this.startOTPInterval();

      UtilStorage.setItem("loginStep", String(ELoginStep.CheckOTP));
      UtilStorage.setItem("loginPhone", this.phone);
      UtilStorage.setItem("loginPassword", this.password);
      UtilStorage.setItem(
        "loginNeedRegistration",
        String(this.isNeedRegistration),
      );
      return;
    }

    if (
      response?.otp_status === "DUPLICATE_CALL" ||
      response?.otp_status === "OTP_ERROR"
    ) {
      UtilError.validateErrorMessage(response.detail, this.phone);
      this.resetData();
      Sentry.captureMessage("Error with activateUser", {
        extra: {
          responseBack: JSON.stringify(response),
          requestData: `phone: ${this.phone}, password: ${this.password}`,
        },
      });
      return;
    }

    this.resetData();
    UtilError.validateErrorMessage(response?.otp_status, this.phone);

    Sentry.captureMessage("Error with activateUser", {
      extra: {
        responseBack: JSON.stringify(response),
        requestData: `phone: ${this.phone}, password: ${this.password}`,
      },
    });
  }

  private async checkIsSuccessLogin() {
    const phone = await UtilStorage.getItem("phone");

    if (phone) {
      this.phone = "+" + phone;
      this.currentStep = ELoginStep.CreatePass;
    }
  }

  private resetData() {
    this.phone = "";
    this.password = "";
    this.passwordRepeat = "";
    this.otp = "";
    this.termsConditionFirst = false;
    this.termsConditionSecond = false;
    this.isNeedRegistration = false;
    this.hasRequest = false;
    this.currentStep = ELoginStep.EnterPhone;
    UtilStorage.removeItem("loginPhone");
    UtilStorage.removeItem("loginPassword");
    UtilStorage.removeItem("loginStep");
    UtilStorage.removeItem("loginNeedRegistration");
  }

  private async openLink(link: string) {
    await Browser.open({ url: link });
  }

  private changePhone() {
    this.resetData();
    UtilStorage.removeItem("phone");
  }

  private openTelegram() {
    window.open(process.env.VUE_APP_TELEGRAM_URL, "_blank");
    this.resetData();
  }

  async activated() {
    if (this.currentStep != ELoginStep.CreatePass) {
      this.checkIsSuccessLogin();
    }
    if (this.$PLATFORM === "ios") {
      const wrapper = document.getElementById("login");
      (wrapper as HTMLElement).style.minHeight = "-webkit-fill-available";
      (wrapper as HTMLElement).style.position = "relative";
    }

    const loginStep = await UtilStorage.getItem("loginStep");

    if (Number(loginStep)) {
      const loginPhone = await UtilStorage.getItem("loginPhone");
      const loginPassword = await UtilStorage.getItem("loginPassword");
      const loginNeedRegistration = await UtilStorage.getItem(
        "loginNeedRegistration",
      );

      if (
        Number(loginStep) === ELoginStep.CheckOTP &&
        loginPhone &&
        loginPassword
      ) {
        this.phone = loginPhone;
        this.password = loginPassword;
        this.currentStep = ELoginStep.CheckOTP;
        if (loginNeedRegistration == "true") {
          this.isNeedRegistration = true;
        }
        // Проверка не запущен ли уже таймер на отсчет
        if (this.nextCallTime === 100) {
          this.startOTPInterval();
        }
      }
    }
  }

  async created() {
    this.checkIsSuccessLogin();
    if (this.$PLATFORM != "web") {
      const appInfo = await App.getInfo();
      this.appVersion = appInfo.version;
      this.appBuild = appInfo.build;
    }
  }

  private openWhatsApp() {
    this.helpLoginDialog = false;
    const phoneNumber = encodeURI(process.env.VUE_APP_WHATSAPP_NUMBER);
    let message = `Проблема со входом. Тип приложения: ${this.$APP_TYPE}. `;
    if (this.phone.length > 0) message += `Номер телефона: ${this.phone}`;
    message = encodeURI(message);
    Browser.open({
      url: `https://wa.me/${phoneNumber}?text=${message}`,
    });
  }
}
</script>
