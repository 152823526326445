import $api from "@/api/ApiInteceptor";
import { ICabinet } from "@/struct/IHomePage/ICabinetBalance";
import { EWebMethod } from "@/struct/IRequest/IRequest";

export default class ApiHome {
  public static async getTelegramConnect(): Promise<boolean> {
    try {
      const response = await $api.call(EWebMethod.GET, "/auth/is-telegram");
      return response.data;
    } catch (e) {
      return false;
    }
  }

  public static async getCabinets(): Promise<ICabinet[] | boolean> {
    try {
      const response = await $api.call(
        EWebMethod.GET,
        "/api/v4/mobile/driver/cabinets",
      );
      return response.data;
    } catch (e) {
      return false;
    }
  }

  public static async sendCreateTransaction(cabinet: string, amount: number) {
    try {
      const data = JSON.stringify({
        cabinet_id: cabinet,
        amount: amount,
      });
      const response = await $api.call(
        EWebMethod.POST,
        `/api/v4/mobile/driver/payout`,
        data,
      );
      return response.data;
    } catch (e) {
      return false;
    }
  }
}
