<template>
  <div v-auto-animate>
    <div
      v-if="!isTelegramConnect"
      class="p-3 flex bg-[#FFDBD766] flex-col items-center text-center rounded-lg border-[#FFB9B6] border-2"
    >
      <div>
        Звонки с кодом приходят не всегда. Подключите телеграм для получения
        кодов.
        <span class="text-blue underline cursor-pointer" @click="openVideo"
          >Это безопасно.</span
        >
      </div>
      <button-component
        @clickAction="openTelegram"
        title="Подключить"
        rounded="lg"
        height="10"
        class="mt-2"
      />
    </div>
    <div
      v-if="infoModal"
      class="p-3 flex bg-[#FFDBD766] flex-col items-center text-center rounded-lg border-[#FFB9B6] border-2"
    >
      <div>Вернитесь в начало и установите пароль через телеграм</div>
    </div>
  </div>
</template>

<script lang="ts">
import ApiHome from "@/api/ApiHome";
import ButtonComponent from "@/components/Common/ButtonComponent.vue";
import * as Sentry from "@sentry/vue";
import { Options, Vue } from "vue-class-component";

@Options({
  components: { ButtonComponent },
  name: "TelegramBanner",
})
export default class TelegramBanner extends Vue {
  private isTelegramConnect = true;
  private infoModal = false;

  private async getTelegramConnect() {
    this.isTelegramConnect = await ApiHome.getTelegramConnect();
  }

  private openTelegram() {
    window.open(process.env.VUE_APP_TELEGRAM_URL, "_blank");

    Sentry.captureMessage("Redirect to connect Telegram");

    this.isTelegramConnect = true;
    if (this.$router.currentRoute.value.path === "/") {
      this.infoModal = true;
    } else {
      this.$router.push("/home");
    }
  }

  private openVideo() {
    window.open(
      "https://rutube.ru/video/39b0df68ed6dfffaf1809b40d6876c8a/",
      "_blank",
    );
  }

  mounted() {
    this.getTelegramConnect();
  }
}
</script>
