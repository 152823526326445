<template>
  <standart-template>
    <PageHeader title="Добавление карты" back-url="home" />
    <ChooseSBPBank v-model="allBankDialog">
      <template v-slot:body>
        <div class="w-full mt-4">
          <InputComponent
            :model="bankFilter"
            v-model="bankFilter"
            background="bg-greyLight"
            placeholder="Поиск"
            maxlength="32"
          />
        </div>

        <div
          class="mt-2"
          v-show="allBanksListFilter && allBanksListFilter?.length > 0"
        >
          <div
            v-for="(item, index) in allBanksListFilter"
            :key="index + 100"
            class="px-3 py-4 border-opacity-75"
            :class="{ 'border-t': index != 0 }"
            @click="chooseOtherBank(item)"
          >
            <div>{{ item.bank_name }}</div>
          </div>
        </div>
        <div
          class="mt-4 flex justify-center"
          v-show="allBanksListFilter?.length == 0"
        >
          Банк не найден
        </div>
      </template>
    </ChooseSBPBank>

    <!--    Добавление СБП-->
    <section class="mt-3">
      <div class="flex items-center font-medium text-grey">
        <img class="mr-3" src="../../assets/img/add-card/sbp.svg" />
        <div>СБП</div>
      </div>
      <div class="relative mt-4 flex w-full items-center text-grey">
        <div class="w-full" v-auto-animate>
          <!--  -->
          <div
            @click="toggleFormSBP()"
            v-if="!isAddSBP"
            class="flex min-h-[113px] w-full items-center rounded-md bg-greyLight p-5"
          >
            <SBPIcon
              class="absolute bottom-0 right-0 rounded-br-md opacity-20"
              color="#A6B0CF"
            />
            <div class="w-full" v-if="!isAddSBP">
              <div>Нажмите, чтобы</div>
              <div>добавить СБП</div>
            </div>
          </div>
          <div
            class="relative w-full rounded-md bg-yellowLight p-5"
            v-if="isAddSBP && !isSendOtpSbp"
          >
            <SBPIcon
              class="absolute bottom-0 right-0 rounded-br-md opacity-20"
              color="#FFD92F"
            />
            <div>
              <div>Название карты</div>
              <div class="mt-1">
                <InputComponent
                  placeholder="Карта СБП"
                  rounded="lg"
                  :model="sbpCardName"
                  v-model="sbpCardName"
                  height="h-10"
                  maxlength="30"
                  :border="false"
                  @keyup="removeNumberInName('sbpCardName')"
                />
              </div>
            </div>
            <div class="mt-4">
              <div>Номер телефона</div>
              <div class="mt-1">
                <InputComponent
                  placeholder="(***) *** ** **"
                  rounded="lg"
                  :model="sbpNumber"
                  v-model="sbpNumber"
                  height="h-10"
                  @paste="onPaste"
                  type="tel"
                  maxlength="15"
                  prefix="+7"
                  :mask="'(###) ### ## ##'"
                  :border="false"
                />
              </div>
            </div>
            <div class="mt-4">Выберите банк из списка</div>
            <div
              class="mt-1 flex flex-nowrap overflow-y-hidden whitespace-nowrap pb-3"
              ref="banksList"
            >
              <div
                @click="chooseSbpBank = null"
                class="z-10 mr-3 flex h-[120px] min-w-[120px] flex-col justify-between rounded-md p-3 bg-greyLight transition-all duration-75"
                :class="{ 'border-2 border-blue': !chooseSbpBank }"
                v-if="otherBank"
              >
                <div>
                  <img
                    :src="require(`@/assets/img/add-card/sbp-color.svg`)"
                    alt="bank image"
                    class="h-[28px]"
                  />
                </div>
                <div class="text-sm truncate">
                  {{ otherBank.bank_name }}
                </div>
              </div>
              <template v-for="(item, index) in mainBanksList" :key="index">
                <div
                  class="z-10 mr-3 flex h-[120px] min-w-[120px] flex-col justify-between rounded-md p-3 transition-all duration-75"
                  :class="{
                    'border-2 border-blue':
                      chooseSbpBank &&
                      chooseSbpBank.identifier === item.identifier,
                  }"
                  :style="{ backgroundColor: item.color }"
                  @click="choosePopularBank(item)"
                >
                  <div>
                    <img
                      class="h-[28px]"
                      :src="
                        require(`@/assets/img/add-card/sbp-banks/${item.icon}.svg`)
                      "
                      alt="bank image"
                    />
                  </div>
                  <div>
                    <div class="text-sm">
                      {{ item.displayName }}
                    </div>
                    <div
                      class="text-sm"
                      v-if="item.displayDescription.length > 1"
                    >
                      {{ item.displayDescription }}
                    </div>
                  </div>
                </div>
              </template>
              <div
                class="z-10 mr-3 flex h-[120px] min-w-[120px] flex-col justify-between rounded-md p-3 bg-greyLight"
                @click="allBankDialog = true"
              >
                <div>
                  <img
                    :src="require(`@/assets/img/add-card/sbp-color.svg`)"
                    alt="bank image"
                    class="h-[28px]"
                  />
                </div>
                <div class="text-sm">Все банки</div>
              </div>
            </div>
          </div>
          <div
            class="relative w-full rounded-md bg-yellowLight p-5 font-medium"
            v-if="isSendOtpSbp"
          >
            <SBPIcon
              color="#FFD92F"
              class="absolute bottom-0 right-0 rounded-br-md opacity-20"
            />
            <div>
              <div>
                <div v-if="typeOtp === 'phone'">
                  На ваш телефон поступит звонок
                  <div>Возьмите трубку и прослушайте код</div>
                </div>

                <div v-if="typeOtp === 'telegram'">
                  На ваш Telegram поступил код
                  <div>Прочитайте и впишите ниже</div>
                </div>
              </div>
              <div class="mt-4">
                <InputComponent
                  :placeholder="
                    typeOtp == 'phone' ? 'Код из звонка' : 'Код из Telegram'
                  "
                  rounded="lg"
                  :model="otpNumber"
                  v-model="otpNumber"
                  height="h-10"
                  maxlength="5"
                  :border="false"
                />
              </div>

              <template v-if="callTries < 3 && typeOtp == 'phone'">
                <div
                  class="mt-2 flex justify-center text-grey"
                  v-if="nextCallTime > 0"
                >
                  Позвонить еще раз ({{ nextCallTime }}c)
                </div>
                <div
                  v-else
                  class="mt-2 flex cursor-pointer justify-center text-blue"
                  @click="onSubmitNewSbp"
                >
                  Позвонить еще раз
                </div>
              </template>
            </div>
            <TelegramBanner v-if="typeOtp === 'phone'" class="mt-5" />
          </div>
        </div>
      </div>
      <div v-auto-animate>
        <div class="mt-4 flex justify-center" v-if="isAddSBP && !isSendOtpSbp">
          <ButtonComponent
            input-class="px-10"
            rounded="lg"
            height="10"
            :loading="hasRequest"
            @clickAction="onSubmitNewSbp"
            disabled-text="Проверяем"
            title="Сохранить"
            :disabled="sbpNumber.length != 15"
          />
        </div>
        <div class="mt-4 flex justify-center" v-if="isSendOtpSbp">
          <ButtonComponent
            input-class="px-10"
            :loading="hasRequest"
            rounded="lg"
            height="10"
            @clickAction="codeConfirmation"
            title="Сохранить"
            :disabled="otpNumber.length != 5"
          />
        </div>
      </div>
    </section>

    <!--    Добавление карты-->
    <section class="mt-6">
      <div class="flex items-center font-medium text-grey">
        <img class="mr-3" src="../../assets/img/add-card/card-full.svg" />
        <div>Карта</div>
      </div>
      <div class="relative mt-4 flex w-full items-center text-grey">
        <div class="w-full" v-auto-animate>
          <div
            @click="toggleFormCard()"
            v-if="!isAddCard"
            class="flex min-h-[113px] w-full items-center rounded-md bg-greyLight p-5"
          >
            <CardIcon
              color="#A6B0CF"
              class="absolute bottom-0 right-0 rounded-br-md opacity-20"
            />
            <div class="w-full" v-if="!isAddCard">
              <div>Нажмите, чтобы</div>
              <div>добавить карту</div>
            </div>
          </div>
          <div
            class="relative w-full rounded-md bg-yellowLight p-5"
            v-if="isAddCard && !isSendOtp"
          >
            <CardIcon
              color="#FFD92F"
              class="absolute bottom-0 right-0 rounded-br-md opacity-20"
            />
            <div>
              <div>Название карты</div>
              <div class="mt-1">
                <InputComponent
                  placeholder="Номер карты в это поле не писать!"
                  rounded="lg"
                  :model="cardName"
                  :disabled="hasRequest"
                  v-model="cardName"
                  pattern="[^0-9]*"
                  height="h-10"
                  maxlength="30"
                  :border="false"
                  @keyup="removeNumberInName('cardName')"
                />
              </div>
            </div>
            <div class="mt-4">
              <div>Номер карты</div>
              <div class="mt-1">
                <InputComponent
                  placeholder="4144 7656 6633 3232"
                  rounded="lg"
                  :model="cardNumber"
                  v-model="cardNumber"
                  height="h-10"
                  :disabled="hasRequest"
                  type="tel"
                  :mask="'#### #### #### #### ###'"
                  :border="false"
                />
              </div>
            </div>
          </div>
          <div
            class="relative w-full rounded-md bg-yellowLight p-5"
            v-if="isSendOtp"
          >
            <CardIcon
              color="#FFD92F"
              class="absolute bottom-0 right-0 rounded-br-md opacity-20"
            />
            <div>
              <div v-if="typeOtp === 'phone'">
                На ваш телефон поступит звонок
                <div>Возьмите трубку и прослушайте код</div>
                <!-- <div>Иногда звонок приходит только через минуту</div> -->
              </div>
              <div v-if="typeOtp === 'telegram'">
                На ваш Telegram поступил код
                <div>Прочитайте и впишите ниже</div>
              </div>
              <div class="mt-4">
                <InputComponent
                  :placeholder="
                    typeOtp == 'phone' ? 'Код из звонка' : 'Код из Telegram'
                  "
                  rounded="lg"
                  :model="otpNumber"
                  v-model="otpNumber"
                  :disabled="hasRequest"
                  height="h-10"
                  maxlength="5"
                  type="tel"
                  :border="false"
                />
              </div>
              <template v-if="callTries < 3 && typeOtp == 'phone'">
                <div
                  class="mt-2 flex justify-center text-grey"
                  v-if="nextCallTime > 0"
                >
                  Позвонить еще раз ({{ nextCallTime }}c)
                </div>
                <div
                  v-else
                  class="mt-2 flex cursor-pointer justify-center text-blue"
                  @click="onSubmitNewCard"
                >
                  Позвонить еще раз
                </div>
              </template>
            </div>
            <TelegramBanner v-if="typeOtp === 'phone'" class="mt-5" />
          </div>
        </div>
      </div>
      <div v-auto-animate>
        <div class="mt-4 flex justify-center" v-if="isAddCard && !isSendOtp">
          <ButtonComponent
            input-class="px-10"
            rounded="lg"
            height="10"
            :loading="hasRequest"
            @clickAction="onSubmitNewCard"
            :title="validateSaveButtonName()"
            :disabled="!validateCard"
          />
        </div>
        <div class="mt-4 flex justify-center" v-if="isSendOtp">
          <ButtonComponent
            input-class="px-10"
            :loading="hasRequest"
            rounded="lg"
            height="10"
            @clickAction="codeConfirmation"
            title="Сохранить"
            :disabled="otpNumber.length != 5"
          />
        </div>
      </div>
    </section>

    <StatusModal
      type="success"
      description="Реквизит успешно добавлен"
      button-text="Супер"
      @confirm="routeBack"
      :state="stateSuccessAddCard"
    >
    </StatusModal>

    <WikiComponent link="https://help.force2.ru/#/docs/addcard" />
  </standart-template>
</template>

<script lang="ts">
import ApiCard from "@/api/ApiCard";
import ButtonComponent from "@/components/Common/ButtonComponent.vue";
import InputComponent from "@/components/Common/InputComponent.vue";
import PageHeader from "@/components/Common/PageHeader.vue";
import CardIcon from "@/components/Icons/CardIcon.vue";
import SBPIcon from "@/components/Icons/SBPIcon.vue";
import TelegramBanner from "@/components/MainPage/TelegramBanner.vue";
import ChooseSBPBank from "@/components/Modals/ChooseSBPBank.vue";
import ConfirmModal from "@/components/Modals/ConfirmModal.vue";
import StatusModal from "@/components/Modals/StatusModal.vue";
import StandartTemplate from "@/components/Template/StandartTemplate.vue";
import WikiComponent from "@/components/Wiki/WikiComponent.vue";
import {
  IBankIcons,
  IPopularSBPBank,
  ISBPBank,
} from "@/struct/IAddCard/IAddCard";
import UtilError from "@/util/ValidateError/UtilError";
import { Haptics, ImpactStyle } from "@capacitor/haptics";
import { Toast } from "@capacitor/toast";
import { Action } from "s-vuex-class";
import { Options, Vue } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { toast } from "vue3-toastify";

@Options({
  components: {
    WikiComponent,
    CardIcon,
    TelegramBanner,
    SBPIcon,
    ChooseSBPBank,
    StatusModal,
    ConfirmModal,
    ButtonComponent,
    InputComponent,
    StandartTemplate,
    PageHeader,
  },
})
export default class AddCard extends Vue {
  @Action("fetchUserCards") private fetchUserCards: (() => void) | undefined;

  @Watch("cardNumber")
  validateCardNumber() {
    const cardNumber = this.cardNumber.replace(/\s/g, "");
    const checkCard = (cardNo: string) => {
      let s = 0;
      let doubleDigit = false;
      for (let i = cardNo.length - 1; i >= 0; i--) {
        let digit = +cardNo[i];
        if (doubleDigit) {
          digit *= 2;
          if (digit > 9) digit -= 9;
        }
        s += digit;
        doubleDigit = !doubleDigit;
      }
      return s % 10 == 0;
    };

    this.validateCard =
      (cardNumber.length === 16 ||
        cardNumber.length === 19 ||
        cardNumber.length === 18) &&
      checkCard(cardNumber);
  }

  removeNumberInName(field: "cardName" | "sbpCardName") {
    this[field] = this[field].replace(/[^a-zA-Zа-яА-Я ]/g, "");
  }

  get allBanksListFilter() {
    return this.allBanksList?.filter((item) =>
      item.bank_name.toLowerCase().includes(this.bankFilter.toLowerCase()),
    );
  }

  private isAddCard = false;
  private isSendOtp = false;
  private cardName = "";
  private cardNumber = "";
  private otpNumber = "";
  private hasRequest = false;
  private stateSuccessAddCard = false;
  private validateCard = false;
  private nextCallTime = 60;
  private callTries = 0;

  private typeOtp: "phone" | "telegram" = "phone";

  private isAddSBP = false;
  private isSendOtpSbp = false;
  private sbpNumber = "";
  private sbpCardName = "";

  private mainBanksList: IPopularSBPBank[] = [];
  private chooseSbpBank: IPopularSBPBank | null = null;

  private allBanksList: ISBPBank[] = [];
  private bankFilter = "";
  private allBankDialog = false;
  private otherBank: ISBPBank | null = null;

  public async onSubmitNewSbp() {
    if (!this.isBankChosen()) {
      UtilError.validateErrorMessage("sbp_bank_not_chosen");
      return;
    }

    if (this.isCardNameTooShort(this.sbpCardName)) {
      UtilError.validateErrorMessage("short_card_name");
      return;
    }

    this.removeNumberInName("sbpCardName");

    const bankIdentifier = this.getSelectedBankIdentifier();
    const phoneNumber = this.formatPhoneNumber(this.sbpNumber);
    const sbpCardName = this.normalizeCardName(this.sbpCardName);

    this.hasRequest = true;
    const response = await ApiCard.createSBPCard(
      sbpCardName,
      phoneNumber,
      bankIdentifier,
    );
    this.processApiResponse(response, "sbp");
    this.hasRequest = false;
  }

  public async onSubmitNewCard() {
    if (this.isCardNameTooShort(this.cardName)) {
      UtilError.validateErrorMessage("short_card_name");
      return;
    }
    this.removeNumberInName("cardName");
    const cardNumber = this.normalizeCardNumber(this.cardNumber);
    const cardName = this.normalizeCardName(this.cardName);

    this.hasRequest = true;
    const response = await ApiCard.createCard(cardName, cardNumber);
    this.processApiResponse(response, "regular");
    this.hasRequest = false;
  }

  private processConfirmation(response: any, type: "sbp" | "regular") {
    if (response.otp_status?.detail === "TM_SEND") {
      this.typeOtp = "telegram";
    }
    this[`isSendOtp${type === "sbp" ? "Sbp" : ""}`] = true;
    this.callTries += 1;
    this.startOTPInterval();
  }

  private resetCardName() {
    this.cardName = "";
  }

  private processApiResponse(response: any, type: "sbp" | "regular") {
    if (
      response?.detail === "DRIVER_SBP_ALREADY_EXIST" ||
      response?.detail === "DRIVER_CARD_ALREADY_EXIST"
    ) {
      this.resetCardName();
      UtilError.validateErrorMessage(response.detail);
    } else if (
      response?.detail === "DRIVER_SBP_CREATE_CONFIRMATION" ||
      response?.detail === "DRIVER_CARD_CREATE_CONFIRMATION"
    ) {
      this.processConfirmation(response, type);
    }
  }

  private toggleFormSBP(): unknown {
    this.cardNumber = "";
    this.cardName = "";
    this.sbpCardName = "";
    this.sbpNumber = "";
    this.chooseSbpBank = null;
    this.isAddSBP = true;
    if (this.isAddCard) {
      return (this.isAddCard = false);
    }
  }
  private toggleFormCard(): unknown {
    this.cardNumber = "";
    this.cardName = "";
    this.sbpCardName = "";
    this.sbpNumber = "";
    this.chooseSbpBank = null;
    this.isAddCard = true;
    if (this.isAddSBP) {
      return (this.isAddSBP = false);
    }
  }

  private isBankChosen(): boolean {
    return !!this.chooseSbpBank || !!this.otherBank;
  }

  private isCardNameTooShort(cardName: string): boolean {
    return cardName.trim().length < 3;
  }

  private getSelectedBankIdentifier(): string {
    return (
      this.chooseSbpBank?.identifier || (this.otherBank?.identifier as string)
    );
  }

  private formatPhoneNumber(number: string): string {
    return "+7" + number.replace(/\D/g, "");
  }

  private normalizeCardNumber(cardNumber: string): string {
    return cardNumber.split(" ").join("");
  }

  private normalizeCardName(cardName: string): string {
    return cardName.trim();
  }

  public onPaste(evt: ClipboardEvent) {
    if (!evt.clipboardData) {
      return;
    }

    let data = evt.clipboardData.getData("text").split("+").join("");
    data = data.split(" ").join("");

    if (data.length === 11 && ["7", "8"].includes(data.charAt(0))) {
      this.sbpNumber = data.substring(1);
    }
  }

  public chooseOtherBank(item: ISBPBank) {
    this.otherBank = item;
    this.chooseSbpBank = null;
    this.$refs.banksList.scrollLeft = 0;
    this.allBankDialog = false;

    if (this.$PLATFORM != "web") {
      Haptics.impact({ style: ImpactStyle.Light });
    }
  }

  public choosePopularBank(item: IPopularSBPBank) {
    this.chooseSbpBank = item;
    if (this.$PLATFORM != "web") {
      Haptics.impact({ style: ImpactStyle.Light });
    }
  }

  public validateSaveButtonName() {
    const cardNumber = this.cardNumber.split(" ").join("");
    const isValidLength = this.isValidCardLength(cardNumber.length);

    // Если длина номера карты валидна, возвращаем на основе валидности карты либо "Сохранить", либо "Неверный номер карты"
    if (isValidLength) {
      return this.validateCard ? "Сохранить" : "Неверный номер карты";
    }

    return this.cardNumber.length <= 0
      ? "Заполните данные"
      : "Неверный номер карты";
  }

  private isValidCardLength(length: number): boolean {
    return [16, 18, 19].includes(length);
  }

  public async codeConfirmation() {
    const INVALID_CONFIRMATION_CODE = "invalid_code";
    const DRIVER_CARD_CREATED = "card_created";

    try {
      this.hasRequest = true;
      const response = await ApiCard.codeConfirmation(this.otpNumber);

      // Обработка неправильного кода подтверждения
      if (response?.detail === INVALID_CONFIRMATION_CODE) {
        await this.handleInvalidCode();
        return;
      }

      // Обработка успешного создания карты водителя
      if (response?.detail === DRIVER_CARD_CREATED) {
        await this.handleSuccessAddCard();
        return;
      }
    } finally {
      // Гарантируем, что hasRequest будет сброшен в любом случае
      this.hasRequest = false;
    }
  }

  private startOTPInterval() {
    this.nextCallTime = 100;
    const otpInterval = setInterval(() => {
      this.nextCallTime -= 1;

      if (this.nextCallTime <= 0) {
        clearInterval(otpInterval);
      }
    }, 1000);
  }

  private async getAllBanksMembers() {
    const response: { identifier: string; bank_name: string }[] | boolean =
      await ApiCard.getSBPMembers();

    if (!Array.isArray(response)) return;

    const bankIcons: IBankIcons = {
      сбер: {
        icon: "sberbank",
        color: "#E5FFD9",
        displayName: "Сбер",
        displayDescription: "",
      },
      альфа: {
        icon: "alfa",
        color: "#FFDBD7",
        displayName: "Альфа",
        displayDescription: "",
      },
      тинькофф: {
        icon: "tinkoff",
        color: "#FFEDAA",
        displayName: "Т-Банк",
        displayDescription: "(Тинькофф)",
      },
    };

    const needBanksList = ["сбер", "альфа", "тинькофф"];

    this.allBanksList = response;

    response?.forEach((item) => {
      needBanksList?.forEach((bank) => {
        if (item.bank_name.toLowerCase().includes(bank.toLowerCase())) {
          const bankInfo = bankIcons[bank];
          if (bankInfo) {
            this.mainBanksList.push({
              ...item,
              ...bankInfo,
            });
          }
        }
      });
    });
  }

  // Обработка успешного добавления карты
  async handleSuccessAddCard() {
    this.resetCardInfo();
    if (this.$PLATFORM !== "web") {
      Haptics.impact({ style: ImpactStyle.Light });
    }
  }

  async handleInvalidCode() {
    this.otpNumber = "";
    if (this.$PLATFORM != "web") {
      await Toast.show({
        text: "Введен неправильный код, попробуйте еще раз!",
      });
    } else {
      await toast("Введен неправильный код, попробуйте еще раз!", {
        autoClose: 3000,
        type: "warning",
      });
    }
  }

  private routeBack() {
    this.fetchUserCards?.();
    this.stateSuccessAddCard = false;
    this.$router.push("/home");
  }

  private resetCardInfo() {
    this.stateSuccessAddCard = true;
    this.isAddCard = false;
    this.isSendOtp = false;
    this.cardName = "";
    this.cardNumber = "";
    this.otpNumber = "";
  }

  created() {
    this.getAllBanksMembers();

    if (this.$route.query?.open) {
      if (this.$route.query?.open == "sbp") {
        this.isAddSBP = true;
      }

      if (this.$route.query?.open == "card") {
        this.isAddCard = true;
      }
    }
  }
}
</script>
